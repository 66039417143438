import {
	tenants,
	type TenantKey,
	type Tenant,
	type TenantId,
	type LiveTenant,
} from "./config";
import { getTenantByID, getTenantByKey } from "./utils";
import { type TenantConfig } from "./types";

export {
	tenants,
	getTenantByID,
	getTenantByKey,
	TenantKey,
	Tenant,
	TenantId,
	LiveTenant,
	TenantConfig,
};
