import {
	tenants,
	type TenantId,
	type Tenant,
	type TenantKey,
	type LiveTenant,
} from "./config";

export function getTenantByID(tenantId: TenantId): LiveTenant {
	const tenant = Object.values(tenants).find((tenant) => {
		if (tenant.status !== "live") {
			return false;
		}
		return tenant.tenantId === tenantId;
	});

	if (!tenant) {
		throw new Error(`Tenant with id ${tenantId} not found`);
	}

	if (tenant.status !== "live") {
		throw new Error(`Tenant with id ${tenantId} is not live`);
	}

	return tenant;
}

export function getTenantByKey(key: TenantKey): Tenant {
	const tenant = tenants[key];

	if (!tenant) {
		throw new Error(`Tenant with key ${key} not found`);
	}

	return tenant;
}
