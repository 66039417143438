import { gql } from "@apollo/client";

import * as fragments from "./fragments";

export const CREATE_ACCOUNT = gql`
	mutation CreateAccount(
		$email: String!
		$firstName: String!
		$lastName: String!
		$password: String!
		$termsAgreed: Boolean!
	) {
		createAccount(
			account: {
				email: $email
				firstName: $firstName
				lastName: $lastName
				password: $password
				termsAgreed: $termsAgreed
			}
		) {
			account {
				...accountFields
			}
			features {
				...featureFlagFields
			}
			session {
				...sessionFields
			}
		}
	}
	${fragments.ACCOUNT}
	${fragments.FEATURE_FLAG}
	${fragments.SESSION}
`;

export const ACTIVATE_ACCOUNT = gql`
	mutation AccountAccount(
		$token: String!
		$password: String!
		$termsAgreed: Boolean!
	) {
		activateAccount(
			form: { token: $token, password: $password, termsAgreed: $termsAgreed }
		) {
			account {
				...accountFields
			}
			features {
				...featureFlagFields
			}
			session {
				...sessionFields
			}
		}
	}
	${fragments.ACCOUNT}
	${fragments.FEATURE_FLAG}
	${fragments.SESSION}
`;

export const CONFIRM_AUSTRALIAN_ACCREDITATION = gql`
	mutation ConfirmAustralianAccreditation(
		$id: ID!
		$doesNotQualify: Boolean!
		$sophisticatedInvestor: Boolean!
		$securitiesInvestmentsExceed10m: Boolean!
		$dealerActingAsPrincipal: Boolean!
		$superannuationOrDepositFundAssetsExceed10m: Boolean!
		$regulatedByAPRA: Boolean!
		$registeredUnderFinancialCorporationsAct: Boolean!
		$listedEntity: Boolean!
		$exemptPublicAuthority: Boolean!
		$businessOfFinancialProductsInvestment: Boolean!
		$foreignEntityCoveredByPreceding: Boolean!
		$adviser: Adviser
	) {
		confirmAustralianAccreditation(
			form: {
				id: $id
				doesNotQualify: $doesNotQualify
				sophisticatedInvestor: $sophisticatedInvestor
				securitiesInvestmentsExceed10m: $securitiesInvestmentsExceed10m
				dealerActingAsPrincipal: $dealerActingAsPrincipal
				superannuationOrDepositFundAssetsExceed10m: $superannuationOrDepositFundAssetsExceed10m
				regulatedByAPRA: $regulatedByAPRA
				registeredUnderFinancialCorporationsAct: $registeredUnderFinancialCorporationsAct
				listedEntity: $listedEntity
				exemptPublicAuthority: $exemptPublicAuthority
				businessOfFinancialProductsInvestment: $businessOfFinancialProductsInvestment
				foreignEntityCoveredByPreceding: $foreignEntityCoveredByPreceding
				adviser: $adviser
			}
		) {
			...accreditationFields
		}
	}
	${fragments.ACCREDITATION}
`;

export const CONFIRM_NEW_ZEALAND_ACCREDITATION = gql`
	mutation ConfirmNewZealandAccreditation(
		$id: ID!
		$doesNotQualify: Boolean!
		$eligibleInvestor: Boolean!
		$netAssetsExceed5m: Boolean!
		$turnoverExceeds5m: Boolean!
		$own1mFinancialProducts: Boolean!
		$transacted1mFinancialProducts: Boolean!
		$investmentDecisionParticipant: Boolean!
		$schemeManager: Boolean!
		$principalBusinessAdvising: Boolean!
		$principalBusinessInvesting: Boolean!
		$principalBusinessBrokering: Boolean!
		$principalBusinessTrading: Boolean!
		$authorisedAdviserOrQFE: Boolean!
		$adviser: Adviser
	) {
		confirmNewZealandAccreditation(
			form: {
				id: $id
				doesNotQualify: $doesNotQualify
				eligibleInvestor: $eligibleInvestor
				netAssetsExceed5m: $netAssetsExceed5m
				turnoverExceeds5m: $turnoverExceeds5m
				own1mFinancialProducts: $own1mFinancialProducts
				transacted1mFinancialProducts: $transacted1mFinancialProducts
				investmentDecisionParticipant: $investmentDecisionParticipant
				schemeManager: $schemeManager
				principalBusinessAdvising: $principalBusinessAdvising
				principalBusinessInvesting: $principalBusinessInvesting
				principalBusinessBrokering: $principalBusinessBrokering
				principalBusinessTrading: $principalBusinessTrading
				authorisedAdviserOrQFE: $authorisedAdviserOrQFE
				adviser: $adviser
			}
		) {
			...accreditationFields
		}
	}
	${fragments.ACCREDITATION}
`;

export const CONFIRM_ACCREDITATION = gql`
	mutation ConfirmAccreditation($id: ID!, $country: ID!, $file: Upload!) {
		confirmAccreditation(form: { id: $id, country: $country, file: $file }) {
			...investingEntityFields
		}
	}
	${fragments.INVESTING_ENTITY}
`;

export const CREATE_BENEFICIAL_OWNER_IDENTITY_DOCUMENT = gql`
	mutation CreateBeneficialOwnerIdentityDocument(
		$token: String!
		$type: String!
		$country: String!
		$addressCity: String
		$addressCountry: String
		$addressLine1: String
		$addressPlaceId: String
		$addressPostCode: String
		$addressRawSearch: String
		$addressSuburb: String
		$fields: [IdentityDocumentFieldInput!]!
	) {
		createBeneficialOwnerIdentityDocument(
			form: {
				token: $token
				type: $type
				country: $country
				fields: $fields
				addressPlaceId: $addressPlaceId
				addressRawSearch: $addressRawSearch
				addressLine1: $addressLine1
				addressCity: $addressCity
				addressSuburb: $addressSuburb
				addressPostCode: $addressPostCode
				addressCountry: $addressCountry
			}
		) {
			...deprecatedBeneficialOwnerFields
		}
	}
	${fragments.DEPRECATED_BENEFICIAL_OWNER}
`;

export const DEPRECATED_UPLOAD_BENEFICIAL_OWNER_ADDRESS_DOCUMENT = gql`
	mutation UploadBeneficialOwnerAddressDocument(
		$token: String!
		$file: Upload!
		$addressRawSearch: String
		$addressPlaceId: String
		$addressLine1: String
		$addressSuburb: String
		$addressCity: String
		$addressPostCode: String
		$addressCountry: String
	) {
		uploadBeneficialOwnerAddressDocument(
			form: {
				token: $token
				file: $file
				addressRawSearch: $addressRawSearch
				addressPlaceId: $addressPlaceId
				addressLine1: $addressLine1
				addressSuburb: $addressSuburb
				addressCity: $addressCity
				addressPostCode: $addressPostCode
				addressCountry: $addressCountry
			}
		) {
			...deprecatedBeneficialOwnerFields
		}
	}
	${fragments.DEPRECATED_BENEFICIAL_OWNER}
`;

export const PROVIDE_BENEFICIAL_OWNER_BASIC_INFORMATION = gql`
	mutation ProvideBeneficialOwnerBasicInformation(
		$token: String!
		$firstName: String!
		$middleName: String
		$lastName: String!
		$dateOfBirth: DayMonthYearInput!
		$autocompleteAddress: AutocompleteAddressInput
		$manualAddress: ManualAddressInput
		$consent: Boolean!
	) {
		provideBeneficialOwnerBasicInformation(
			form: {
				token: $token
				firstName: $firstName
				middleName: $middleName
				lastName: $lastName
				dateOfBirth: $dateOfBirth
				autocompleteAddress: $autocompleteAddress
				manualAddress: $manualAddress
				consent: $consent
			}
		) {
			...beneficialOwnerFields
		}
	}
	${fragments.BENEFICIAL_OWNER}
`;

export const COMPLETE_BENEFICIAL_OWNER_BIOMETRIC_VERIFICATION = gql`
	mutation CompleteBeneficialOwnerBiometricVerification($token: String!) {
		completeBeneficialOwnerBiometricVerification(form: { token: $token }) {
			...beneficialOwnerFields
		}
	}
	${fragments.BENEFICIAL_OWNER}
`;

export const CONFIRM_BENEFICIAL_OWNER_IDENTITY_DOCUMENT_DETAILS = gql`
	mutation ConfirmBeneficialOwnerIdentityDocumentDetails(
		$token: String!
		$type: IdentityDocumentType!
		$country: ID!
		$firstName: String!
		$middleName: String!
		$lastName: String!
		$dateOfBirth: DayMonthYearInput!
		$identityDocumentFields: [IdentityDocumentFieldInput!]!
	) {
		confirmBeneficialOwnerIdentityDocumentDetails(
			form: {
				token: $token
				type: $type
				country: $country
				firstName: $firstName
				middleName: $middleName
				lastName: $lastName
				dateOfBirth: $dateOfBirth
				identityDocumentFields: $identityDocumentFields
			}
		) {
			...beneficialOwnerFields
		}
	}
	${fragments.BENEFICIAL_OWNER}
`;

export const UPLOAD_BENEFICIAL_OWNER_ADDRESS_DOCUMENT = gql`
	mutation UploadBeneficialOwnerAddressDocument(
		$token: String!
		$file: Upload!
		$manualAddress: ManualAddressInput
		$autocompleteAddress: AutocompleteAddressInput
	) {
		uploadBeneficialOwnerAddressDocument(
			form: {
				token: $token
				file: $file
				manualAddress: $manualAddress
				autocompleteAddress: $autocompleteAddress
			}
		) {
			...beneficialOwnerFields
		}
	}
	${fragments.BENEFICIAL_OWNER}
`;

export const CONFIRM_IDENTITY_DOCUMENT_DETAILS = gql`
	mutation ConfirmIdentityDocumentDetails(
		$type: IdentityDocumentType!
		$country: ID!
		$firstName: String!
		$middleName: String!
		$lastName: String!
		$dateOfBirth: DayMonthYearInput!
		$identityDocumentFields: [IdentityDocumentFieldInput!]!
	) {
		confirmIdentityDocumentDetails(
			form: {
				type: $type
				country: $country
				firstName: $firstName
				middleName: $middleName
				lastName: $lastName
				dateOfBirth: $dateOfBirth
				identityDocumentFields: $identityDocumentFields
			}
		) {
			...accountFields
		}
	}
	${fragments.ACCOUNT}
`;

export const COMPLETE_ACCOUNT_BIOMETRIC_VERIFICATION = gql`
	mutation CompleteAccountBiometricVerification {
		completeAccountBiometricVerification {
			id
		}
	}
`;

export const CREATE_IDENTITY_VERIFICATION = gql`
	mutation CreateIdentityVerification(
		$type: String!
		$country: String!
		$firstName: String!
		$middleName: String
		$lastName: String!
		$dayOfBirth: Int!
		$monthOfBirth: Int!
		$yearOfBirth: Int!
		$placeOfBirthCity: String
		$placeOfBirthCountry: String
		$placeOfBirthRawSearch: String
		$placeOfBirthPlaceId: String
		$identityDocumentFields: [IdentityDocumentFieldInput!]!
	) {
		createIdentityVerification(
			form: {
				type: $type
				country: $country
				firstName: $firstName
				middleName: $middleName
				lastName: $lastName
				dayOfBirth: $dayOfBirth
				monthOfBirth: $monthOfBirth
				yearOfBirth: $yearOfBirth
				placeOfBirthCity: $placeOfBirthCity
				placeOfBirthCountry: $placeOfBirthCountry
				placeOfBirthRawSearch: $placeOfBirthRawSearch
				placeOfBirthPlaceId: $placeOfBirthPlaceId
				identityDocumentFields: $identityDocumentFields
			}
		) {
			...accountFields
		}
	}
	${fragments.ACCOUNT}
`;

export const PROVIDE_OUTSTANDING_INFORMATION = gql`
	mutation ProvideOutstandingInformation(
		$placeOfBirthCity: String!
		$placeOfBirthCountry: String!
		$placeOfBirthRawSearch: String
		$placeOfBirthPlaceId: String
	) {
		provideOutstandingInformation(
			form: {
				placeOfBirthCity: $placeOfBirthCity
				placeOfBirthCountry: $placeOfBirthCountry
				placeOfBirthRawSearch: $placeOfBirthRawSearch
				placeOfBirthPlaceId: $placeOfBirthPlaceId
			}
		) {
			id
		}
	}
`;

export const CREATE_ORDER = gql`
	mutation CreateOrder(
		$offerId: ID!
		$investingEntityId: ID!
		$unitCount: Int!
		$hasConfirmedReadDocumentation: Boolean!
		$hasAcknowledgedAccreditationIsRequired: Boolean
	) {
		createOrder(
			order: {
				offerId: $offerId
				investingEntityId: $investingEntityId
				unitCount: $unitCount
				hasConfirmedReadDocumentation: $hasConfirmedReadDocumentation
				hasAcknowledgedAccreditationIsRequired: $hasAcknowledgedAccreditationIsRequired
			}
		) {
			id
			created
		}
	}
`;

export const UPDATE_BANK_ACCOUNT = gql`
	mutation UpdateBankAccount($id: ID!, $nickname: String) {
		updateBankAccount(form: { id: $id, nickname: $nickname }) {
			...verifiableBankAccountFields
		}
	}
	${fragments.VERIFIABLE_BANK_ACCOUNT}
`;

export const UPLOAD_BANK_ACCOUNT_VERIFICATION_DOCUMENT = gql`
	mutation UploadBankAccountVerificationDocument(
		$id: ID!
		$name: String
		$nickname: String
		$accountNumber: String
		$bsbCode: String
		$routingNumber: String
		$sortCode: String
		$file: Upload!
	) {
		uploadBankAccountVerificationDocument(
			form: {
				id: $id
				name: $name
				nickname: $nickname
				accountNumber: $accountNumber
				bsbCode: $bsbCode
				routingNumber: $routingNumber
				sortCode: $sortCode
				file: $file
			}
		) {
			...verifiableBankAccountFields
		}
	}
	${fragments.VERIFIABLE_BANK_ACCOUNT}
`;

export const CREATE_NZD_BANK_ACCOUNT = gql`
	mutation CreateNZDBankAccount(
		$name: String
		$nickname: String!
		$accountNumber: String!
		$file: Upload!
		$investingEntityId: ID!
	) {
		createNZDBankAccount(
			form: {
				name: $name
				nickname: $nickname
				accountNumber: $accountNumber
				file: $file
				investingEntityId: $investingEntityId
			}
		) {
			...verifiableBankAccountFields
		}
	}
	${fragments.VERIFIABLE_BANK_ACCOUNT}
`;

export const CREATE_AUD_BANK_ACCOUNT = gql`
	mutation CreateAUDBankAccount(
		$name: String
		$nickname: String!
		$accountNumber: String!
		$bsbCode: String!
		$file: Upload!
		$investingEntityId: ID!
	) {
		createAUDBankAccount(
			form: {
				name: $name
				nickname: $nickname
				accountNumber: $accountNumber
				bsbCode: $bsbCode
				file: $file
				investingEntityId: $investingEntityId
			}
		) {
			...verifiableBankAccountFields
		}
	}
	${fragments.VERIFIABLE_BANK_ACCOUNT}
`;

export const CREATE_USD_BANK_ACCOUNT = gql`
	mutation CreateUSDBankAccount(
		$name: String
		$nickname: String!
		$accountNumber: String!
		$routingNumber: String!
		$file: Upload!
		$investingEntityId: ID!
	) {
		createUSDBankAccount(
			form: {
				name: $name
				nickname: $nickname
				accountNumber: $accountNumber
				routingNumber: $routingNumber
				file: $file
				investingEntityId: $investingEntityId
			}
		) {
			...verifiableBankAccountFields
		}
	}
	${fragments.VERIFIABLE_BANK_ACCOUNT}
`;

export const CREATE_GBP_BANK_ACCOUNT = gql`
	mutation CreateGBPBankAccount(
		$name: String
		$nickname: String!
		$accountNumber: String!
		$sortCode: String!
		$file: Upload!
		$investingEntityId: ID!
	) {
		createGBPBankAccount(
			form: {
				name: $name
				nickname: $nickname
				accountNumber: $accountNumber
				sortCode: $sortCode
				file: $file
				investingEntityId: $investingEntityId
			}
		) {
			...verifiableBankAccountFields
		}
	}
	${fragments.VERIFIABLE_BANK_ACCOUNT}
`;

export const CREATE_EUR_BANK_ACCOUNT = gql`
	mutation CreateEURBankAccount(
		$name: String
		$nickname: String!
		$accountNumber: String!
		$file: Upload!
		$investingEntityId: ID!
	) {
		createEURBankAccount(
			form: {
				name: $name
				nickname: $nickname
				accountNumber: $accountNumber
				file: $file
				investingEntityId: $investingEntityId
			}
		) {
			...verifiableBankAccountFields
		}
	}
	${fragments.VERIFIABLE_BANK_ACCOUNT}
`;

export const CREATE_SESSION = gql`
	mutation CreateSession($email: String!, $password: String!) {
		createSession(account: { email: $email, password: $password }) {
			account {
				...accountFields
			}
			features {
				...featureFlagFields
			}
			session {
				...sessionFields
			}
		}
	}
	${fragments.ACCOUNT}
	${fragments.FEATURE_FLAG}
	${fragments.SESSION}
`;

export const CREATE_SESSION_FROM_TOKEN = gql`
	mutation CreateSessionFromToken($token: String!) {
		createSessionFromToken(account: { token: $token }) {
			account {
				...accountFields
			}
			features {
				...featureFlagFields
			}
			session {
				...sessionFields
			}
		}
	}
	${fragments.ACCOUNT}
	${fragments.FEATURE_FLAG}
	${fragments.SESSION}
`;

export const COMPLETE_ACCOUNT_DETAILS = gql`
	mutation CompleteAccountDetails(
		$addressPlaceId: String
		$addressRawSearch: String
		$addressLine1: String!
		$addressCity: String!
		$addressSuburb: String!
		$addressPostCode: String!
		$addressCountry: String!
		$phoneNumber: String!
		$callingCode: String!
		$primaryCitizenshipCountry: String!
		$availableFunds: AccountAvailableFunds!
		$consent: Boolean!
	) {
		completeAccountDetails(
			form: {
				addressPlaceId: $addressPlaceId
				addressRawSearch: $addressRawSearch
				addressLine1: $addressLine1
				addressCity: $addressCity
				addressSuburb: $addressSuburb
				addressPostCode: $addressPostCode
				addressCountry: $addressCountry
				phoneNumber: $phoneNumber
				callingCode: $callingCode
				primaryCitizenshipCountry: $primaryCitizenshipCountry
				availableFunds: $availableFunds
				consent: $consent
			}
		) {
			...accountFields
		}
	}
	${fragments.ACCOUNT}
`;

export const COMPLETE_BENEFICIAL_OWNER_CONTACT_DETAILS = gql`
	mutation CompleteBeneficialOwnerContactDetails(
		$contactDetails: [BeneficialOwnerContactDetailsInput!]!
	) {
		completeBeneficialOwnerContactDetails(
			form: { contactDetails: $contactDetails }
		) {
			...investingEntityFields
		}
	}
	${fragments.INVESTING_ENTITY}
`;

export const DESTROY_SESSION = gql`
	mutation DestroySession($token: JWT!) {
		destroySession(session: { token: $token }) {
			message
			statusCode
		}
	}
`;

export const RESEND_EMAIL_CONFIRMATION = gql`
	mutation ResendEmailConfirmation($email: String!) {
		resendEmailConfirmation(account: { email: $email }) {
			message
			statusCode
		}
	}
`;

export const RESET_PASSWORD = gql`
	mutation ResetPassword($email: String!) {
		resetPassword(account: { email: $email }) {
			message
			statusCode
		}
	}
`;

export const SEND_CHANGE_PASSWORD_EMAIL = gql`
	mutation SendChangePasswordEmail {
		sendChangePasswordEmail {
			id
			email
		}
	}
`;

export const SET_NEW_PASSWORD = gql`
	mutation SetNewPassword(
		$token: String!
		$newPassword: String!
		$confirmPassword: String!
	) {
		setNewPassword(
			form: {
				newPassword: $newPassword
				confirmPassword: $confirmPassword
				token: $token
			}
		) {
			account {
				...accountFields
			}
			features {
				...featureFlagFields
			}
			session {
				...sessionFields
			}
		}
	}
	${fragments.ACCOUNT}
	${fragments.FEATURE_FLAG}
	${fragments.SESSION}
`;

export const UPDATE_PROFILE = gql`
	mutation UpdateProfile(
		$timeZone: String
		$callingCode: String
		$phoneNumber: String
		$preferredName: String
		$autocompleteAddress: AutocompleteAddressInput
		$manualAddress: ManualAddressInput
	) {
		updateProfile(
			form: {
				timeZone: $timeZone
				callingCode: $callingCode
				phoneNumber: $phoneNumber
				preferredName: $preferredName
				autocompleteAddress: $autocompleteAddress
				manualAddress: $manualAddress
			}
		) {
			...accountFields
		}
	}
	${fragments.ACCOUNT}
`;

export const UPLOAD_ADDRESS_VERIFICATION_DOCUMENT = gql`
	mutation UploadAddressVerificationDocument(
		$addressCity: String
		$addressCountry: String
		$addressLine1: String
		$addressPlaceId: String
		$addressPostCode: String
		$addressRawSearch: String
		$addressSuburb: String
		$file: Upload!
	) {
		uploadAddressVerificationDocument(
			form: {
				addressCity: $addressCity
				addressCountry: $addressCountry
				addressLine1: $addressLine1
				addressRawSearch: $addressRawSearch
				addressSuburb: $addressSuburb
				addressPlaceId: $addressPlaceId
				addressPostCode: $addressPostCode
				file: $file
			}
		) {
			...accountFields
		}
	}
	${fragments.ACCOUNT}
`;

export const REGISTER_INTEREST = gql`
	mutation RegisterInterest($offerId: ID!, $unitCount: Int!) {
		registerInterest(form: { offerId: $offerId, unitCount: $unitCount }) {
			id
			slug
			investor {
				...offerInvestorFields
			}
		}
	}
	${fragments.OFFER_INVESTOR}
`;

export const UPLOAD_INVESTING_ENTITY_DOCUMENTS = gql`
	mutation UploadInvestingEntityDocuments(
		$investingEntityId: ID!
		$files: [Upload!]!
	) {
		uploadInvestingEntityDocuments(
			form: { investingEntityId: $investingEntityId, files: $files }
		) {
			investingEntity {
				id
				documents {
					...remoteAssetFields
				}
			}
		}
	}
	${fragments.REMOTE_ASSET}
`;

export const PROVIDE_SOURCE_OF_FUNDS = gql`
	mutation ProvideSourceOfFunds(
		$id: ID!
		$sourceOfFunds: SourceOfFundsSource!
		$document: Upload!
		$supportingInformation: String
	) {
		provideSourceOfFunds(
			form: {
				id: $id
				sourceOfFunds: $sourceOfFunds
				document: $document
				supportingInformation: $supportingInformation
			}
		) {
			...investingEntityFields
		}
	}
	${fragments.INVESTING_ENTITY}
`;

export const WITHDRAW_FROM_WALLET = gql`
	mutation WithdrawFromWallet($currencyId: ID!, $amount: Float!) {
		withdrawFromWallet(form: { currencyId: $currencyId, amount: $amount }) {
			wallet {
				...walletFields
			}
			transaction {
				...deprecatedTransactionFields
			}
		}
	}
	${fragments.WALLET}
	${fragments.DEPRECATED_TRANSACTION}
`;

export const UPDATE_HOLDING = gql`
	mutation updateHolding($id: ID!, $returnsTarget: ReturnsTarget!) {
		updateHolding(form: { id: $id, returnsTarget: $returnsTarget }) {
			...holdingFields
		}
	}
	${fragments.HOLDING}
`;
