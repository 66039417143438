export type TenantStatus = "live" | "demo";

export type InvestorPortalLogoSize = "s" | "m" | "lg" | "xl" | "2xl";

export enum CurrencyId {
	AUD = "36",
	NZD = "554",
	GBP = "826",
	USD = "840",
	EUR = "978",
}

type TenantConfigBase = {
	name: string;
	// Todo: Rename this to investorApplications
	investorPortal: {
		/** Controls the display size of the tenants logo */
		logoSize: InvestorPortalLogoSize;
		/** Design system customization colors must be supplied as hex */
		colors: {
			primary: string;
			secondary: string;
			appHeaderBackground: string;
		};
		/** Used to resolve tenants assets in Investor portal usually lowercase tenant name */
		assetPath: `/${string}`;
		/** User name when basic auth is enabled (defaults to caruso if not set) */
		basicAuthUsername: string | null;
	};
	urls: {
		/** Support url is used in investor portal can be a mailto link or url */
		supportUrl: string | null;
		/** The url of the tenants investor portal */
		investorPortalUrl: string | null;
		/** The url of the tenants wholesale certification document */
		wholesaleCertificationUrl: string | null;
		/** The url of the tenants terms and conditions document */
		termsAndConditionsUrl: string | null;
		/** The url of the tenants privacy policy document */
		privacyPolicyUrl: string | null;
	};
};

type TenantConfigInactive = TenantConfigBase & {
	status: "demo";
	postalAddress?: string;
	supportEmail?: string;
};

type TenantConfigLive = TenantConfigBase & {
	status: "live";
	/** Postal address for the fund manager (rendered in subscription docs) */
	postalAddress: string;
	/** Differs from support url expecting an email address here (this is rendered during document generation) */
	supportEmail: string;
	/** The tenant ID this should be in notion contact a backend team member if missing */
	tenantId: string;
	adminApp: {
		/** The default currency in admin app */
		defaultCurrencyId?: CurrencyId;
	};
};

export type TenantConfig = TenantConfigInactive | TenantConfigLive;
